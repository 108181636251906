/// <reference path='./View.ts'/>
class VideoPlayer extends View{
	private playerState:any;

    public playerHolder:JQuery;
    public isPlaying: boolean = false;
    public reshow: boolean = false;
	private videoLink:string = "";
    private messageDiv: JQuery;
    private progressbar: JQuery;
    private player: JQuery;
    private closeBtn: JQuery;
    private messageCount: number = 0;

	public static PLAY:string = "playvideo";
	public static VIDEO_END:string = "videoend";
	public static GET_VIDEO:string = "getVideo";
	public static SHOW_PLAYER:string = "showplayer";
    public static GOT_ID:string = "gotId";

	/**
	 * Stores the global vars, sets the references to the video node, and loader.
	 * @param container    	A jQuery object containing the parent div for this component.
	 * @param data          The JSON data unique to this component.
	 * @param delegate    	The Class that created this instance.
	 */
    constructor(container:JQuery, data:any, id:string){

		super(container, data ,id);

		this.setContent(Main.templates.find('.videoPlayer').clone());
        this.stateChanged();
        
        EventBus.addEventListener(VideoPlayer.SHOW_PLAYER, ()=>this.init(), this);
        EventBus.addEventListener(VideoPlayer.GET_VIDEO, ()=> this.getVideo(), this);
        EventBus.addEventListener(VideoPlayer.GOT_ID, (d)=> this.redirect(d), this);
        
        this.playerHolder = this.content.find('#playerHolder');
        this.messageDiv = this.content.find('.messageText');
        this.progressbar = this.content.find('.progressbar');
        this.closeBtn = this.content.find('.btnClose');

        this.content.find('.btnClose').on('click', ()=>this.closePlayer());
		this.resize();
		$(window).bind('resize', $.proxy(this.resize, this));

        if(this.expId){
            this.init();
			this.getVideo();
        }
	}

	private init(){
        this.reshow = true;
		this.showMe(this.subSection);
        this.player = $("<video preload controls autoplay></video>");
        this.playerHolder.append(this.player);
	}

	/**
	 * Loads a video through the video node, binds the canplaythrough event.
	 * @data 		The url and poster frame for the video we want to load.
	 */
	public loadVideo(data:VideoParams){
        this.hideElements();
        if (this.player) {
            let playerDiv: any = this.player;
            playerDiv[0].src = data.src;
            this.player.addClass('show');
            playerDiv[0].play();

            let mobileCheck = Utils.detectMobileOS();
            if (mobileCheck.os != "unknown") {
                if (data.poster) {
                    playerDiv[0].poster = data.poster;
                } else {
                    playerDiv[0].poster = "../siteshare.jpg"
                }
            }
        }
        this.isPlaying = true;
        $("#socialContainer").removeClass('hide');
        ga('send', 'event', 'video', 'play');

    }


	private getVideo(){
	    let data = {
	        'expId' : this.expId, 
	        'actId' : Main.config.actId,
	    }
        this.imposium.on('statusupdate',  (e)=>this.onStatusUpdate(e), this);
	    this.imposium.startEventProcessor(data, $.proxy(this.gotScene, this), $.proxy(this.eventProcessorError, this));
	}
	
	public gotScene(data) {
	    if(data.type == "video"){
	    	let videoData = { src: data.mp4Url, poster: null}
            EventBus.dispatchEvent(Social.GEN_BITLY);
			this.loadVideo(videoData)
	    }
	}

	private eventProcessorError(e){
	    console.error("Experience Error");
	    console.log(e);
	}

	private onStatusUpdate(status){
	    if(this.onStage){
            var bar = this.content.find('.bar')
            var width = 100/7;
            var nWidth = this.messageCount*width
            bar.css('width',nWidth+'%')

            this.messageCount++;
        }
    }

	private hideElements(){
        this.progressbar.addClass('hide');
	}
	
	private showElements(){
        this.messageCount = 0;
        this.progressbar.removeClass('hide');
        this.reshow = false;
        this.content.find('.bar').css('width', "0%")
	}

	public resize(){

		var h = window.innerHeight;
		var w = window.innerWidth;

		var pos = Utils.fitToContainer({
			'containerWidth':w,
			'containerHeight':h,
			'contentWidth':1280,
			'contentHeight':720,
			'vAlign':'center',
			'hAlign':'center',
			'scaleMode':'proportionalInside'
		});

		if(this.playerHolder){
			this.playerHolder.css(pos);
		}

	}

	private closePlayer(){
       Utils.pushState('/');

       ga('send', 'event', 'player', 'closed');
       this.hideMe();
	}

    private redirect(data){
        if(this.onStage){
            Utils.pushState('/v/' + data.experienceId);
            this.closeBtn.show();
            document.title = "Raise One For Gord";
        }
    }

	public hideMe(){
        super.hideMe();
        if(this.player) {
            this.player.remove();
            this.player = null;
        }
        if(this.reshow){
        	this.showElements();

        }
        $("#socialContainer").removeClass('hide');
        if(this.closeBtn){
            this.closeBtn.hide();
        }
        EventBus.dispatchEvent(Social.GEN_BITLY);
	}
}
interface VideoParams{
	src:string;
	poster?:string;
}