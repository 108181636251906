class View{

    /**
     * The config JSON data associated with this view.
     */    
    public data:any;

    /**
     * The unique ID associated with this view, this matched against the browser state to see if this view should be visible.
     */
    public id:string;     

    public expId:string;     

    public imposium:any;
    /**
     * The object containing event listeners added to this specific component
     */
    private listeners:any = {};

    /**
     * The object containing event listeners to be fired once added to this component
     */
    private onceListeners:any = {};

    /**
     * Rivets.js instance for binding this.data to this.content
     */
    private rivets:any;

    /**
     * The text to show in the header for this view.
     */
    public displayText:string;     

    /**
     * The jquery object container for this view.
     */    
    public content:JQuery;    

    /**
     * Whether or not this view is currently visible or not.
     */    
    public onStage:boolean = false;

    /**
     * The active sub section for this view, set by the updateMe function.
     */    
    public subSection:string = null;

    /**
     * The delay for setting this view to display:none once the showContent class has been removed.
     */
    public hideDelay:number = 0;

    /**
     * The button created to show / hide this view in the header
     */
    public trigger:any;

    /**
     * The global timeout set when showing / hiding this view
     */
    public displayTimeout:number;

    public container: JQuery;

    /**
     * Stores the global vars, and adds the state change listener
     * @param content     A jQuery object containing the parent div for this view.
     * @param data         The JSON data bound to this views content.
     * @param id         The unique ID associated with this view, used to determine if this view should be visible or not by listening to the browser state.
     */
    constructor(container:JQuery, data:any, id:string){

        //set the variables passed in from the parent object
        this.container = container;
        this.id = id; 
        this.data = data;
        this.onStage = false;
        History['Adapter'].bind(window,'statechange',()=>this.stateChanged());

        this.imposium = new Imposium.ImposiumClient();
        this.rivets = rivets.bind(this.container, {
            "controller":this,
            "data":this.data
        });
    }


    public setContent(v:JQuery):void {

        this.content = v;

        this.rivets = rivets.bind(this.content, {data:this.data});
        this.container.append(this.content);
    }

    /**
     * Triggered anytime the browser URL state changes, determines if this view should be visible or not.
     */
    public stateChanged():void{

        //get the state
        let urlStrings = History['getState']().url.split("/");
        let stateString = urlStrings[3];
        let subString = (urlStrings[4]) ?  ( urlStrings[4].length > 0) ?  urlStrings[4] : null : null;
        //if we are just updating the sub class
        if(stateString == this.id &&  subString != null){
            this.expId = subString;
            Main.currentExperience = this.expId;
            this.showMe(subString);
            EventBus.dispatchEvent(VideoPlayer.GET_VIDEO);
        }else if(stateString != this.id){
            this.hideMe();
            this.expId =  null;
            Main.currentExperience = null;
            this.subSection = null;

        //check to see if we need to show the class
        }else if(stateString == this.id && !this.onStage){
            this.showMe(subString);
            this.expId = null;
        }
    }

    /**
     * Called when the stateChanged function sees that this view should be visible, and isn't already.
     * @param subState        Name of the sub view passed in from stateChanged.
     */
    public showMe(subSection:string):void{
        
        if(this.subSection != subSection){
            this.updateMe(subSection);
        }

        this.content.css('display', 'block');
        this.onStage = true;

        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout( ()=>{
            this.showPageContent();
        }, 30);

        if(this.trigger) this.trigger.find('a').addClass('active');
    }


    /**
     * Called when the stateChanged function sees that this view shouldn't be visible, and is.
     */
    public hideMe():void{

        this.hidePageContent();
        
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout( ()=>{
            this.onStage = false;    
            this.content.css('display', 'none');
        }, this.hideDelay);

        if(this.trigger) this.trigger.find('a').removeClass('active');
    }

    /**
     * Called when the sub section of this view changes.
     * @param subState        The subState passed in from stateChanged.
     */
    public updateMe(subState:string):void{

        this.subSection = subState;
    }

    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    public dispatch(evt:string, data:any = null) {

        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];

        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }

        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    }

    /**
     * Toggles the show and hide css classes to show the content    
     */
    public showPageContent():void{

        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    }

    /**
     * Toggles the show and hide css classes to show the content    
     */
    public hidePageContent():void{

        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    }
}