/// <reference path='./scaffolding/Main.ts'/>
/// <reference path='./scaffolding/Utils.ts'/>
/// <reference path='./scaffolding/EventBus.ts'/>
/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./scaffolding/VideoPlayer.ts'/>
/// <reference path='./scaffolding/View.ts'/>
/// <reference path='./StreamView.ts'/>
/// <reference path='./LandingView.ts'/>
/// <reference path='./Social.ts'/>

let cont = $("#container");

let main = new Main(CONFIG);

let landingView = new LandingView(cont, {}, "");
let streamView =  new StreamView(cont, {}, "stream");
let videoPlayer = new VideoPlayer(cont, {}, "v");
let social = new Social(cont, {}, this);