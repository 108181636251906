var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
/**
 * Main static class, holds all global config variables and functions for all views and components.
 */
var Main = (function () {
    /**
     * Creates a new Main instance, sets the templates for all views and components to use, creates a global socket connection, and gets the saved state from a cookie.
     *
     * @param config    Configuration variables passed in from config.php.
     */
    function Main(config) {
        var _this = this;
        //set the php config variables
        Main.config = config;
        this.setPrefixes();
        Main.templates = $("#templates");
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
    }
    Main.prototype.resize = function () {
        Main.sWidth = $(window).width();
        Main.sHeight = $(window).height();
    };
    /**
     * Sets Main.xform and Main.transition by detecting which prefix exists on the current browser.
     */
    Main.prototype.setPrefixes = function () {
        var view = document.createElement('div');
        var prefixes = ['webkit', 'Moz', 'O', 'ms'];
        for (var i = 0; i < prefixes.length; i++) {
            var prefix = prefixes[i];
            var e = prefix + 'Transform';
            var t = prefix + 'Transition';
            if (typeof view.style[e] !== 'undefined') {
                Main.xform = e;
                Main.transition = t;
            }
        }
        view = null;
    };
    return Main;
}());
/// <reference path='Main.ts'/>
/**
 * Helper functions available to all other Classes.
 */
var Utils = (function () {
    function Utils() {
    }
    Utils.easeQuadOut = function (e0, e1, t) {
        return e0 + (e1 - e0) * (t * (2 - t));
    };
    Utils.wrapText = function (context, text, x, y, maxWidth, lineHeight) {
        y = lineHeight;
        var words = text.split(' ');
        var line = '';
        for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    };
    Utils.pow2floor = function (v) {
        v++;
        var p = 1;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    Utils.pow2ceil = function (v) {
        v--;
        var p = 2;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/
    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop / position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    Utils.fitToContainer = function (data) {
        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;
        //scale
        if (data.scaleMode == "proportionalInside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "proportionalOutside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH < data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "none" || !data.scaleMode) {
            newW = data.contentWidth;
            newH = data.contentHeight;
        }
        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }
        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }
        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? -(newW - data.containerWidth) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? -(newH - data.containerHeight) : (data.containerHeight - newH) / 2;
        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    };
    /**
     * Generates a UUID and returns it.
     */
    Utils.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    };
    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    Utils.generateID = function (numChars) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    /**
     * Wrapper function to make an ajax request.
     * @data                The JSON data we want to pas in.
     * @successCallback     Callback function for on success.
     * @errorCallback       Callback function for error.
     */
    Utils.ajax = function (data, successCallback, errorCallback, ajaxUrl) {
        if (successCallback === void 0) { successCallback = null; }
        if (errorCallback === void 0) { errorCallback = null; }
        if (ajaxUrl === void 0) { ajaxUrl = null; }
        $.ajax({
            type: 'POST',
            url: 'ajax.cms.php',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorCallback) {
                    errorCallback(jqXHR.responseText);
                }
            }
        });
    };
    /**
     * Pushes a browser state
     * @state   The state we want to push.
     */
    Utils.pushState = function (state) {
        // History['pushState']({state:1}, Main.config.pageTitle, state);
        History['pushState']({ state: 1 }, Main.config.title, state);
    };
    /**
     *Opens a popup window centered in the screen
     */
    Utils.openWindow = function (url, width, height) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        };
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        window.open(url, '' + new Date().getTime() + '', windowFeatures);
    };
    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    Utils.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    Utils.formatBytes = function (bytes) {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else if (bytes >= 1000000) {
            bytes = (bytes / 1000000).toFixed(2) + ' MB';
        }
        else if (bytes >= 1000) {
            bytes = (bytes / 1000).toFixed(2) + ' KB';
        }
        else if (bytes > 1) {
            bytes = bytes + ' bytes';
        }
        else if (bytes == 1) {
            bytes = bytes + ' byte';
        }
        else {
            bytes = '0 byte';
        }
        return bytes;
    };
    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    Utils.objSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key))
                size++;
        }
        return size;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    Utils.isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    Utils.isOdd = function (num) {
        return (num % 2) == 16;
    };
    /**
     * Contain a number to a min and max.
     */
    Utils.clamp = function (min, max, val) {
        if (val < min)
            return min;
        if (val > max)
            return max;
        return val;
    };
    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Convert degrees to radians.
     */
    Utils.degreesToRadians = function (degrees) {
        return degrees * Math.PI / 180;
    };
    /**
     * Convert radians to degrees
     */
    Utils.radiansToDegrees = function (radians) {
        return radians * 180 / Math.PI;
    };
    /**
     * Calculates the distance between two points in 2D space.
     */
    Utils.lineDistance = function (point1, point2) {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    };
    /**
     * Calculates the angle in degrees between two points
     */
    Utils.calcAngle = function (p1, p2) {
        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0)
            calcAngle = Math.abs(calcAngle);
        else
            calcAngle = 360 - calcAngle;
        return calcAngle;
    };
    /**
     * Returns a random number between 2 numbers
     */
    Utils.randomFromInterval = function (from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    };
    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    Utils.swapArrayElements = function (array, a, b) {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    };
    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    Utils.removeFromArray = function (array, from, to) {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    };
    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    Utils.shuffleArray = function (array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    Utils.isValidJSON = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    Utils.formatJSONString = function (str) {
        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    };
    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    Utils.detectMobileOS = function () {
        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;
        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        }
        else if (ua.match(/Android/i)) {
            mobileOS = 'Android';
            uaindex = ua.indexOf('Android ');
        }
        else {
            mobileOS = 'unknown';
        }
        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        }
        else if (mobileOS === 'Android' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 8, 3);
        }
        else {
            mobileOSver = 'unknown';
        }
        var num = Number(mobileOSver);
        return { "os": mobileOS, "ver": num };
    };
    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    Utils.canPlayMP4 = function () {
        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/
    Utils.clientTimezone = function (date) {
        return date.addHours(-(new Date().getTimezoneOffset() / 60));
    };
    Utils.formatDate = function (date, format) {
        var d = Utils.clientTimezone(date);
        return date.toString(format).replace(' 0:', ' 12:'); // Fix date.js bug to show 12:00am instead of 0:00am
    };
    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/
    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exdays      How many days befor this cookie expires.
     */
    Utils.setCookie = function (c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = value + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
        document.cookie = c_name + "=" + c_value;
    };
    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    Utils.getCookie = function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    };
    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/
    /**
     * Validates an email address.
     */
    Utils.realEmail = function (addressToTest) {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    };
    return Utils;
}());
/**
 * Global event listener class. Used for passing global between views and components.
 */
var EventBus = (function () {
    function EventBus() {
    }
    /**
     * Adds an event listener for a global event.
     *
     * @param evt      Event string we're listening for
     * @param callback The callback function to call if the event is dispatched.
     * @param caller   Who called this
     */
    EventBus.addEventListener = function (evt, callback, caller) {
        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = {};
        }
        EventBus.listeners[evt][caller.uuid] = callback;
    };
    /**
     * Removes a specific event listener for a global event.
     *
     * @param evt      Event string we're removing
     * @param callback The callback function we want to remove.
     * @param caller   Who called this
     */
    EventBus.removeEventListener = function (evt, callback, caller) {
        var listeners = EventBus.listeners[evt];
        delete listeners[caller.uuid];
    };
    /**
     * Adds an event listener for a global event.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass into the callback function for that event.
     */
    EventBus.dispatchEvent = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    };
    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */
    EventBus.listeners = {};
    return EventBus;
}());
var Component = (function () {
    /**
     * Stores the global vars
     *
     * @param container A jQuery object containing the parent div for this view.
     * @param data      The config JSON data associated with this component.
     * @param delegate  The Class that instantiated this view.
     */
    function Component(container, data, delegate) {
        /**
         * Whether of not this instance is visible or not.
         */
        this.onStage = false;
        /**
         * The delay for setting display:none on this view after the showContent class is removed.
         */
        this.hideDelay = 0;
        /**
         * The delay for adding the showContent class to this view once display:block has been set.
         */
        this.showDelay = 25;
        /**
         * The object containing event listeners added to this specific component
         */
        this.listeners = {};
        /**
         * The object containing event listeners to be fired once added to this component
         */
        this.onceListeners = {};
        /**
         * The class to use for showing this component
         */
        this.displayClass = "block";
        this.uuid = Utils.generateUUID();
        this.container = container;
        this.delegate = delegate;
        this.data = data;
        this.bindEvents();
    }
    Component.prototype.resize = function (e) {
        if (e === void 0) { e = null; }
        this.sHeight = window.innerHeight;
        this.sWidth = window.innerWidth;
    };
    /**
     * Sets this.content with a JQuery object passed in (usually cloned from Main.templates)
     *
     * @param v JQuery wrapped DOM element
     */
    Component.prototype.setContent = function (v) {
        this.content = v;
        this.rivets = rivets.bind(this.content, { data: this.data });
        this.container.append(this.content);
    };
    /**
     * Adds an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to add an event listener for.
     * @param callback The function we want to call if that event is dispatched.
     * @param caller   Who called this
     */
    Component.prototype.on = function (evt, callback, caller) {
        if (!this.listeners[evt]) {
            this.listeners[evt] = {};
        }
        this.listeners[evt][caller.guid] = callback;
    };
    /**
     * Removes an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to remove an event listener for.
     * @param callback The function we want to remove from the listeners array for that event.
     * @param caller   Who called this
     */
    Component.prototype.off = function (evt, callback, caller) {
        var listeners = this.listeners[evt];
        delete listeners[caller.guid];
    };
    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    Component.prototype.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    };
    /**
     * Binds all of the event listeners for this component.
     */
    Component.prototype.bindEvents = function () {
        var _this = this;
        $(window).bind('resize orientationchange', function (e) { return _this.resize(e); });
    };
    /**
     * Unbinds all of the Event listeners for this component.
     */
    Component.prototype.unbindEvents = function () {
        var _this = this;
        $(window).unbind('resize orientationchange', function (e) { return _this.resize(e); }); //TODO: MAKE THIS WORK
    };
    /**
     * Adds display block to this.content, and calls showContent
     */
    Component.prototype.showMe = function () {
        var _this = this;
        var style = (this.displayClass == "visible") ? "visibility" : "display";
        this.content.css(style, this.displayClass);
        this.onStage = true;
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.showContent();
        }, this.showDelay);
    };
    /**
     * Adds display none to this.content, calls hideContent
     */
    Component.prototype.hideMe = function () {
        var _this = this;
        this.hideContent();
        this.onStage = false;
        var style = (this.displayClass == "visible") ? "visibility" : "display";
        var hide = (style == "display") ? "none" : "hidden";
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.content.css(style, hide);
        }, this.hideDelay);
    };
    /**
     * Adds the showContent class and removes the hideContent class from this component.
     */
    Component.prototype.showContent = function () {
        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    };
    /**
     * Adds the hideContent class and removes the showContent class from this component.
     */
    Component.prototype.hideContent = function () {
        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    };
    /**
     * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
     */
    Component.prototype.killMe = function () {
        this.unbindEvents();
        if (this.content) {
            this.content.remove();
        }
        this.content = null;
    };
    return Component;
}());
var View = (function () {
    /**
     * Stores the global vars, and adds the state change listener
     * @param content     A jQuery object containing the parent div for this view.
     * @param data         The JSON data bound to this views content.
     * @param id         The unique ID associated with this view, used to determine if this view should be visible or not by listening to the browser state.
     */
    function View(container, data, id) {
        var _this = this;
        /**
         * The object containing event listeners added to this specific component
         */
        this.listeners = {};
        /**
         * The object containing event listeners to be fired once added to this component
         */
        this.onceListeners = {};
        /**
         * Whether or not this view is currently visible or not.
         */
        this.onStage = false;
        /**
         * The active sub section for this view, set by the updateMe function.
         */
        this.subSection = null;
        /**
         * The delay for setting this view to display:none once the showContent class has been removed.
         */
        this.hideDelay = 0;
        //set the variables passed in from the parent object
        this.container = container;
        this.id = id;
        this.data = data;
        this.onStage = false;
        History['Adapter'].bind(window, 'statechange', function () { return _this.stateChanged(); });
        this.imposium = new Imposium.ImposiumClient();
        this.rivets = rivets.bind(this.container, {
            "controller": this,
            "data": this.data
        });
    }
    View.prototype.setContent = function (v) {
        this.content = v;
        this.rivets = rivets.bind(this.content, { data: this.data });
        this.container.append(this.content);
    };
    /**
     * Triggered anytime the browser URL state changes, determines if this view should be visible or not.
     */
    View.prototype.stateChanged = function () {
        //get the state
        var urlStrings = History['getState']().url.split("/");
        var stateString = urlStrings[3];
        var subString = (urlStrings[4]) ? (urlStrings[4].length > 0) ? urlStrings[4] : null : null;
        //if we are just updating the sub class
        if (stateString == this.id && subString != null) {
            this.expId = subString;
            Main.currentExperience = this.expId;
            this.showMe(subString);
            EventBus.dispatchEvent(VideoPlayer.GET_VIDEO);
        }
        else if (stateString != this.id) {
            this.hideMe();
            this.expId = null;
            Main.currentExperience = null;
            this.subSection = null;
        }
        else if (stateString == this.id && !this.onStage) {
            this.showMe(subString);
            this.expId = null;
        }
    };
    /**
     * Called when the stateChanged function sees that this view should be visible, and isn't already.
     * @param subState        Name of the sub view passed in from stateChanged.
     */
    View.prototype.showMe = function (subSection) {
        var _this = this;
        if (this.subSection != subSection) {
            this.updateMe(subSection);
        }
        this.content.css('display', 'block');
        this.onStage = true;
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.showPageContent();
        }, 30);
        if (this.trigger)
            this.trigger.find('a').addClass('active');
    };
    /**
     * Called when the stateChanged function sees that this view shouldn't be visible, and is.
     */
    View.prototype.hideMe = function () {
        var _this = this;
        this.hidePageContent();
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.onStage = false;
            _this.content.css('display', 'none');
        }, this.hideDelay);
        if (this.trigger)
            this.trigger.find('a').removeClass('active');
    };
    /**
     * Called when the sub section of this view changes.
     * @param subState        The subState passed in from stateChanged.
     */
    View.prototype.updateMe = function (subState) {
        this.subSection = subState;
    };
    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    View.prototype.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    };
    /**
     * Toggles the show and hide css classes to show the content
     */
    View.prototype.showPageContent = function () {
        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    };
    /**
     * Toggles the show and hide css classes to show the content
     */
    View.prototype.hidePageContent = function () {
        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    };
    return View;
}());
/// <reference path='./View.ts'/>
var VideoPlayer = (function (_super) {
    __extends(VideoPlayer, _super);
    /**
     * Stores the global vars, sets the references to the video node, and loader.
     * @param container    	A jQuery object containing the parent div for this component.
     * @param data          The JSON data unique to this component.
     * @param delegate    	The Class that created this instance.
     */
    function VideoPlayer(container, data, id) {
        var _this = this;
        _super.call(this, container, data, id);
        this.isPlaying = false;
        this.reshow = false;
        this.videoLink = "";
        this.messageCount = 0;
        this.setContent(Main.templates.find('.videoPlayer').clone());
        this.stateChanged();
        EventBus.addEventListener(VideoPlayer.SHOW_PLAYER, function () { return _this.init(); }, this);
        EventBus.addEventListener(VideoPlayer.GET_VIDEO, function () { return _this.getVideo(); }, this);
        EventBus.addEventListener(VideoPlayer.GOT_ID, function (d) { return _this.redirect(d); }, this);
        this.playerHolder = this.content.find('#playerHolder');
        this.messageDiv = this.content.find('.messageText');
        this.progressbar = this.content.find('.progressbar');
        this.closeBtn = this.content.find('.btnClose');
        this.content.find('.btnClose').on('click', function () { return _this.closePlayer(); });
        this.resize();
        $(window).bind('resize', $.proxy(this.resize, this));
        if (this.expId) {
            this.init();
            this.getVideo();
        }
    }
    VideoPlayer.prototype.init = function () {
        this.reshow = true;
        this.showMe(this.subSection);
        this.player = $("<video preload controls autoplay></video>");
        this.playerHolder.append(this.player);
    };
    /**
     * Loads a video through the video node, binds the canplaythrough event.
     * @data 		The url and poster frame for the video we want to load.
     */
    VideoPlayer.prototype.loadVideo = function (data) {
        this.hideElements();
        if (this.player) {
            var playerDiv = this.player;
            playerDiv[0].src = data.src;
            this.player.addClass('show');
            playerDiv[0].play();
            var mobileCheck = Utils.detectMobileOS();
            if (mobileCheck.os != "unknown") {
                if (data.poster) {
                    playerDiv[0].poster = data.poster;
                }
                else {
                    playerDiv[0].poster = "../siteshare.jpg";
                }
            }
        }
        this.isPlaying = true;
        $("#socialContainer").removeClass('hide');
        ga('send', 'event', 'video', 'play');
    };
    VideoPlayer.prototype.getVideo = function () {
        var _this = this;
        var data = {
            'expId': this.expId,
            'actId': Main.config.actId,
        };
        this.imposium.on('statusupdate', function (e) { return _this.onStatusUpdate(e); }, this);
        this.imposium.startEventProcessor(data, $.proxy(this.gotScene, this), $.proxy(this.eventProcessorError, this));
    };
    VideoPlayer.prototype.gotScene = function (data) {
        if (data.type == "video") {
            var videoData = { src: data.mp4Url, poster: null };
            EventBus.dispatchEvent(Social.GEN_BITLY);
            this.loadVideo(videoData);
        }
    };
    VideoPlayer.prototype.eventProcessorError = function (e) {
        console.error("Experience Error");
        console.log(e);
    };
    VideoPlayer.prototype.onStatusUpdate = function (status) {
        if (this.onStage) {
            var bar = this.content.find('.bar');
            var width = 100 / 7;
            var nWidth = this.messageCount * width;
            bar.css('width', nWidth + '%');
            this.messageCount++;
        }
    };
    VideoPlayer.prototype.hideElements = function () {
        this.progressbar.addClass('hide');
    };
    VideoPlayer.prototype.showElements = function () {
        this.messageCount = 0;
        this.progressbar.removeClass('hide');
        this.reshow = false;
        this.content.find('.bar').css('width', "0%");
    };
    VideoPlayer.prototype.resize = function () {
        var h = window.innerHeight;
        var w = window.innerWidth;
        var pos = Utils.fitToContainer({
            'containerWidth': w,
            'containerHeight': h,
            'contentWidth': 1280,
            'contentHeight': 720,
            'vAlign': 'center',
            'hAlign': 'center',
            'scaleMode': 'proportionalInside'
        });
        if (this.playerHolder) {
            this.playerHolder.css(pos);
        }
    };
    VideoPlayer.prototype.closePlayer = function () {
        Utils.pushState('/');
        ga('send', 'event', 'player', 'closed');
        this.hideMe();
    };
    VideoPlayer.prototype.redirect = function (data) {
        if (this.onStage) {
            Utils.pushState('/v/' + data.experienceId);
            this.closeBtn.show();
            document.title = "Raise One For Gord";
        }
    };
    VideoPlayer.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        if (this.player) {
            this.player.remove();
            this.player = null;
        }
        if (this.reshow) {
            this.showElements();
        }
        $("#socialContainer").removeClass('hide');
        if (this.closeBtn) {
            this.closeBtn.hide();
        }
        EventBus.dispatchEvent(Social.GEN_BITLY);
    };
    VideoPlayer.PLAY = "playvideo";
    VideoPlayer.VIDEO_END = "videoend";
    VideoPlayer.GET_VIDEO = "getVideo";
    VideoPlayer.SHOW_PLAYER = "showplayer";
    VideoPlayer.GOT_ID = "gotId";
    return VideoPlayer;
}(View));
/// <reference path='./scaffolding/View.ts'/>
var StreamView = (function (_super) {
    __extends(StreamView, _super);
    function StreamView(container, data, id) {
        var _this = this;
        _super.call(this, container, data, id);
        this.isHls = false;
        this.isiOS = false;
        this.isOffScreen = false;
        this.seeked = false;
        this.isMobile = false;
        this.playerSetUp = false;
        // private stream: string = "http://devimages.apple.com/iphone/samples/bipbop/gear1/prog_index.m3u8";
        this.isPlaying = false;
        this.setContent(Main.templates.find('#streamView').clone());
        this.videoDiv = this.content.find('#vid');
        this.player = this.content.find('.player');
        this.circle = this.content.find('.circle');
        this.playBtn = this.content.find('.playBtn');
        this.nxtBtn = this.content.find('.nextBtn');
        this.nxtBtn.on('click', function () { return _this.goToLanding(); });
        this.stateChanged();
        $(window).bind('resize oriantationchange', $.proxy(this.resize, this));
        this.fitPlayerToScreen();
        this.getUrl();
        var mobileCheck = Utils.detectMobileOS();
        if (mobileCheck.os != "unknown") {
            this.isMobile = true;
            if (mobileCheck.os == "iOS") {
                this.isiOS = true;
            }
        }
        if (this.isMobile) {
            this.player.on('click', function () { return _this.startVideo(); });
        }
    }
    StreamView.prototype.showMe = function () {
        var _this = this;
        _super.prototype.showMe.call(this, this.id);
        this.resize();
        this.content.addClass('move');
        Main.currentExperience = "stream";
        if (this.isOffScreen) {
            this.content.css('right', "0px");
            this.isOffScreen = false;
        }
        if (!this.isPlaying) {
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(function () {
                _this.circle.addClass('hide');
                _this.showVideo();
            }, 2500);
        }
        else {
            this.startVideo();
        }
    };
    StreamView.prototype.showVideo = function () {
        if (this.isMobile) {
            this.addVideo();
            this.isHls = false;
        }
        else {
            this.setupLegacyPlayer();
            this.isHls = true;
        }
    };
    StreamView.prototype.goToLanding = function () {
        Utils.pushState('/');
        ga('send', 'event', 'view', 'landing');
    };
    StreamView.prototype.getUrl = function () {
        var _this = this;
        $.getJSON("https://api.raiseoneforgord.com/stream", function (data) {
            _this.stream = data.url;
        });
    };
    StreamView.prototype.startVideo = function () {
        if (!this.isMobile) {
            if (!this.isOffScreen) {
                jwplayer('legacyPlayer').play();
                this.isPlaying = true;
            }
            this.nxtBtn.addClass('show');
        }
        else {
            var videoTag_1 = this.videoDiv[0];
            this.videoDiv.addClass('show');
            this.playBtn.removeClass('show');
            var currentTime = videoTag_1.currentTime;
            if (!this.seeked) {
                videoTag_1.onplay = function () {
                    var max = videoTag_1.duration * 0.85;
                    var seekTime = Math.random() * (max - 0) + 0;
                    videoTag_1.currentTime = seekTime;
                };
                videoTag_1.load();
                this.seeked = true;
                if (this.isiOS) {
                    this.nxtBtn.addClass('show');
                }
            }
            else {
                videoTag_1.play();
            }
        }
    };
    StreamView.prototype.addVideo = function () {
        var _this = this;
        var videoTag = this.videoDiv[0];
        var src = $('<source src="' + this.stream + '" type="application/x-mpegURL">');
        this.videoDiv.append(src);
        this.isPlaying = true;
        this.playBtn.addClass('show');
        videoTag.addEventListener('ended', function () {
            videoTag.play();
        });
        videoTag.addEventListener('error', function () {
            _this.getUrl();
            var src = $('<source src="' + _this.stream + '" type="application/x-mpegURL">');
            _this.videoDiv.append(src);
        });
    };
    StreamView.prototype.resize = function () {
        this.fitPlayerToScreen();
        if (this.isOffScreen) {
            this.content.removeClass('move');
            this.isOffScreen = false;
            this.moveOff();
        }
    };
    StreamView.prototype.hideMe = function () {
        if (!this.isMobile && jwplayer('legacyPlayer').getState() == "playing") {
            jwplayer('legacyPlayer').pause();
        }
        else {
            var videoTag = this.videoDiv[0];
            videoTag.pause();
        }
        _super.prototype.hideMe.call(this);
        if (!this.isOffScreen) {
            this.moveOff();
        }
    };
    StreamView.prototype.moveOff = function () {
        var _this = this;
        if (!this.isOffScreen) {
            var offset = window.innerWidth;
            this.content.css('right', -offset + "px");
            this.isOffScreen = true;
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(function () {
                _this.content.addClass('move');
            }, 50);
        }
    };
    StreamView.prototype.fitPlayerToScreen = function () {
        var sWidth = window.innerWidth;
        var sHeight = window.innerHeight;
        if (this.isMobile) {
            if (this.isiOS) {
                var pos_1 = Utils.fitToContainer({
                    'containerWidth': sWidth,
                    'containerHeight': sHeight,
                    'contentWidth': 1920,
                    'contentHeight': 1080,
                    'scaleMode': 'proportionalInside',
                    'hAlign': 'center',
                    'vAlign': 'center'
                });
            }
            var pos = {
                'width': sWidth,
                'height': sHeight,
                'top': 0,
                'left': 0
            };
            this.player.css(pos);
        }
        else {
            this.player.css({ 'width': sWidth, 'height': sHeight });
        }
    };
    StreamView.prototype.setupLegacyPlayer = function () {
        var _this = this;
        this.isPlaying = true;
        this.legacyPlayer = jwplayer('legacyPlayer').setup({
            file: this.stream,
            autostart: true,
            controls: true,
            repeat: true,
            height: "100%",
            width: "100%"
        });
        var scope = this;
        jwplayer('legacyPlayer').onTime(function () {
            if (!scope.seeked) {
                var duration = this.getDuration();
                var max = duration * 0.85;
                var seekTime = Math.random() * (max - 0) + 0;
                this.seek(seekTime);
                scope.seeked = true;
                scope.nxtBtn.addClass('show');
            }
        });
        jwplayer('legacyPlayer').onError(function () {
            _this.getUrl();
            jwplayer('legacyPlayer').setup({
                file: _this.stream,
            });
        });
    };
    return StreamView;
}(View));
var StopWatch = (function () {
    function StopWatch() {
        this.displayTime = 0;
    }
    StopWatch.prototype.start = function (container) {
        var _this = this;
        this.displayDiv = container;
        clearInterval(this.interval);
        this.startTime = Date.now();
        this.interval = setInterval(function () { return _this.update(); }, 33);
    };
    StopWatch.prototype.stop = function () {
        clearInterval(this.interval);
        this.startTime = 0;
        this.displayTime = 0;
    };
    StopWatch.prototype.render = function () {
        var time = this.formatTime();
        this.displayDiv.empty().append(time);
    };
    StopWatch.prototype.reset = function () {
        if (this.displayDiv) {
            this.displayDiv.empty().append("00:00:00");
        }
    };
    StopWatch.prototype.update = function () {
        this.displayTime += this.delta();
        this.render();
    };
    StopWatch.prototype.formatTime = function () {
        var sec_num = Math.floor(this.displayTime / 1000);
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        var milliseconds = this.displayTime / 1000;
        var msFix = Math.abs(milliseconds - Math.trunc(milliseconds));
        var msFormat = Math.trunc(msFix * 100);
        // if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        if (msFormat < 10) {
            msFormat = "0" + msFormat;
        }
        return minutes + ':' + seconds + ':' + msFormat;
    };
    StopWatch.prototype.delta = function () {
        var currentTime = Date.now();
        var d = currentTime - this.startTime;
        this.startTime = currentTime;
        return d;
    };
    return StopWatch;
}());
/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./StopWatch.ts'/>
var RecordComponent = (function (_super) {
    __extends(RecordComponent, _super);
    function RecordComponent(container, data, del) {
        var _this = this;
        _super.call(this, container, data, del);
        this.chunks = [];
        this.timerTime = 0;
        this.isRecording = false;
        this.del = del;
        this.setContent(Main.templates.find('.recordView').clone());
        this.mediaConstraints = {
            audio: true,
            video: true
        };
        this.videoDiv = this.content.find('video');
        this.recordBtn = this.content.find('.recordBtn');
        this.subControls = this.content.find('.subControls');
        this.recordScreen = this.content.find('.recordScreen');
        this.timerDiv = this.content.find('.timer');
        this.checkBox = this.content.find('.streamCheck');
        this.recordBtn.on('click', function () { return _this.toggleRecord(); });
        this.content.find('.retryBtn').on('click', function () { return _this.retry(); });
        this.content.find('.keepBtn').on('click', function () { return _this.keep(); });
        this.content.find('.closeBtn').on('click', function () { return _this.hideMe(); });
        this.stopWatch = new StopWatch();
    }
    RecordComponent.prototype.fitScreen = function () {
        var pos = Utils.fitToContainer({
            'containerWidth': this.recordScreen.width(),
            'containerHeight': this.recordScreen.height(),
            'contentWidth': this.videoDiv.width(),
            'contentHeight': this.videoDiv.height(),
            'scaleMode': 'proportionalOutside',
            'hAlign': 'center',
            'vAlign': 'center'
        });
        this.videoDiv.css(pos);
    };
    RecordComponent.prototype.showMe = function () {
        var _this = this;
        _super.prototype.showMe.call(this);
        navigator.getUserMedia(this.mediaConstraints, function (stream) { return _this.onMediaSuccess(stream); }, function (e) { return _this.onMediaError(e); });
    };
    RecordComponent.prototype.onMediaSuccess = function (stream) {
        var _this = this;
        this.stream = stream;
        var options = {
            mimeType: 'video/webm'
        };
        this.streamRecorder = new MediaRecorder(stream, options);
        var vidTag = this.videoDiv[0];
        vidTag.src = URL.createObjectURL(stream);
        vidTag.play();
        setTimeout(function () {
            _this.timerDiv.addClass('show');
            _this.recordBtn.addClass('show');
        }, 2200);
    };
    RecordComponent.prototype.toggleRecord = function () {
        if (!this.isRecording) {
            this.startRecorder();
            this.isRecording = true;
            this.recordBtn.addClass('square');
        }
        else {
            this.stopRecording();
            this.stopWatch.stop();
            this.isRecording = false;
            this.recordBtn.removeClass('square');
        }
    };
    RecordComponent.prototype.startRecorder = function () {
        this.chunks = [];
        this.stopWatch.start(this.timerDiv);
        this.streamRecorder.start();
        var scope = this;
        this.streamRecorder.ondataavailable = function (e) {
            scope.chunks.push(e.data);
        };
    };
    RecordComponent.prototype.retry = function () {
        this.stopWatch.reset();
        this.recordBtn.removeClass('hide');
        this.subControls.removeClass('show');
    };
    RecordComponent.prototype.keep = function () {
        Main.userVideoInStream = this.checkBox.is(':checked');
        this.hideMe();
        this.del.userRecording();
    };
    RecordComponent.prototype.stopTimer = function () {
        this.timerTime = 0;
    };
    RecordComponent.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        if (this.isRecording) {
            this.toggleRecord();
        }
        this.stopWatch.reset();
        var vidTag = this.videoDiv[0];
        vidTag.src = "";
        this.stream.getTracks()[0].stop();
        this.stream.stop();
        this.retry();
    };
    RecordComponent.prototype.stopRecording = function () {
        this.isRecording = false;
        this.streamRecorder.stop();
        this.recordBtn.addClass('hide');
        this.subControls.addClass('show');
    };
    RecordComponent.prototype.onMediaError = function (e) {
        console.error(e);
    };
    return RecordComponent;
}(Component));
/// <reference path='./scaffolding/VideoPlayer.ts'/>
/// <reference path='./scaffolding/View.ts'/>
/// <reference path='./RecordComponent.ts'/>
var LandingView = (function (_super) {
    __extends(LandingView, _super);
    function LandingView(container, data, id) {
        var _this = this;
        _super.call(this, container, data, id);
        this.isUploadedVideo = false;
        this.isRecordVideo = false;
        this.isOffScreen = false;
        this.setContent(Main.templates.find('#landingView').clone());
        this.videoUpload = this.content.find('.videoUpload');
        this.btnRecord = this.content.find('.btnRecord');
        this.checkBox = this.content.find('.streamCheck');
        this.btnSubmit = this.content.find('.btnSubmit');
        this.streamBtn = this.content.find('.stream').on('click', function () { return _this.goToStream(); });
        this.btnUpload = this.content.find('.btnUpload').one('click', $.proxy(this.uploadPhoto, this));
        this.btnSubmit.on("click", function () { return _this.showVideoPlayer(); });
        this.videoUpload.on("change", function () { return _this.showVideoName(); });
        this.btnRecord.on('click', $.proxy(this.showVideoRecorder, this));
        $(window).bind('resize', $.proxy(this.resize, this));
        this.stateChanged();
        var mobileCheck = Utils.detectMobileOS();
        if (mobileCheck.os == "iOS") {
            var width = screen.availWidth;
            var height = screen.availHeight;
            this.container.addClass('safari');
            if (width == 320 && height == 548) {
                this.container.addClass('iphone5');
            }
        }
        if (navigator.userAgent.indexOf('safari') == -1 && mobileCheck.os == "unknown") {
            if (Main.config.browser != "safari") {
                this.recordView = new RecordComponent(this.content, {}, this);
                this.btnRecord.addClass('show');
            }
            else {
                this.btnUpload.addClass('safari');
                this.content.find('input').addClass('safari');
            }
        }
    }
    LandingView.prototype.showMe = function () {
        var _this = this;
        _super.prototype.showMe.call(this, this.id);
        if (this.isOffScreen) {
            this.content.css('left', "0px");
            this.isOffScreen = false;
            setTimeout(function () {
                _this.content[0].style.overflowY = 'auto';
            }, 500);
        }
    };
    LandingView.prototype.goToStream = function () {
        this.moveOff();
        Utils.pushState('/stream');
        this.content[0].style.overflowY = 'hidden';
        ga('send', 'event', 'view', 'stream');
        this.hideMe();
    };
    LandingView.prototype.moveOff = function () {
        var _this = this;
        if (!this.isOffScreen) {
            var offset = window.innerWidth;
            this.content.css('left', -offset + "px");
            this.isOffScreen = true;
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(function () {
                _this.content.addClass('move');
            }, 50);
        }
    };
    LandingView.prototype.uploadPhoto = function () {
        this.videoUpload.click();
        this.isUploadedVideo = true;
        this.isRecordVideo = false;
        this.btnUpload.one('click', $.proxy(this.uploadPhoto, this));
        ga('send', 'event', 'upload', 'Video');
    };
    LandingView.prototype.showVideoPlayer = function () {
        // let videoFile:any = this.videoUpload[0];     
        // if(videoFile.files.length != 0){
        Main.userVideoInStream = this.checkBox.is(':checked');
        this.btnRecord.removeClass('up');
        this.btnUpload.removeClass('up');
        this.content.find('.videoFileName').empty();
        this.content.find('.recordFileName').empty();
        $("#socialContainer").addClass('hide');
        this.handleExperience();
        ga('send', 'event', 'page_view', 'VideoPlayer');
        // }
    };
    LandingView.prototype.showVideoName = function () {
        var videoFile = this.videoUpload[0];
        if (videoFile.files.length != 0) {
            this.isUploadedVideo = true;
            this.isRecordVideo = false;
            this.btnUpload.addClass('up');
            this.btnRecord.removeClass('up');
            this.content.find('.videoFileName').html(videoFile.files[0].name);
            this.content.find('.recordFileName').html('');
        }
    };
    LandingView.prototype.handleExperience = function () {
        if (this.isUploadedVideo) {
            var userVideo = this.videoUpload;
            var videoVal = userVideo[0];
            this.userVideo = videoVal;
        }
        else if (this.isRecordVideo) {
            var scope = this;
            var blob = new Blob(this.recordView.chunks, { type: 'video/webm' });
            var fileOfBlob = new File([blob], 'userVideo.webm');
            this.userVideo = fileOfBlob;
        }
        this.createExperience();
    };
    LandingView.prototype.showVideoRecorder = function () {
        this.recordView.showMe();
        var videoFile = this.videoUpload[0];
        ga('send', 'event', 'page_view', 'VideoRecorder');
    };
    LandingView.prototype.userRecording = function () {
        this.isUploadedVideo = false;
        this.isRecordVideo = true;
        // this.showVideoPlayer();
        this.btnRecord.addClass('up');
        this.btnUpload.removeClass('up');
        var videoFile = this.videoUpload[0];
        if (videoFile.files.length != 0) {
            this.content.find('.videoFileName').empty();
        }
        this.content.find('.recordFileName').html('userRecording.webm');
        ga('send', 'event', 'created', 'Video');
    };
    LandingView.prototype.createExperience = function () {
        EventBus.dispatchEvent(VideoPlayer.SHOW_PLAYER);
        var userName = this.content.find('.name').val();
        var city = this.content.find('.city').val();
        var year = this.content.find('.year').val();
        var where = this.content.find('.where').val();
        var people = this.content.find('.people').val();
        var memory = this.content.find('.memory').val();
        var data = {
            'task': 'solo-experience',
            'id': Utils.generateUUID(),
            'story_id': Main.config.storyId,
            'inventory': {
                "user_video": this.userVideo,
                'addToStream': Main.userVideoInStream,
                'name': userName,
                'venue': where,
                'when': year,
                'with': people,
                'message': memory,
                'where': city
            }
        };
        this.imposium.createExperience(data, $.proxy(this.experienceCreated, this), $.proxy(this.experienceError, this));
    };
    //Once the experience is created, make sure we have an experience id, and start the exent processor
    LandingView.prototype.experienceCreated = function (expData) {
        var userVideo = this.videoUpload;
        var value = userVideo[0];
        this.isUploadedVideo = false;
        this.isRecordVideo = false;
        value.value = "";
        if (expData.bind.success == 1) {
            this.experienceId = expData.bind.experience_id;
            EventBus.dispatchEvent(VideoPlayer.GOT_ID, { experienceId: this.experienceId });
            ga('send', 'event', 'experience', 'created');
        }
        else {
            this.experienceError(null);
        }
    };
    LandingView.prototype.resize = function () {
        if (this.isOffScreen) {
            this.content.removeClass('move');
            this.isOffScreen = false;
            this.moveOff();
        }
    };
    LandingView.prototype.experienceError = function (e) {
        console.error("Experience Error");
        console.log(e);
        ga('send', 'event', 'experience', 'error');
    };
    LandingView.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        if (!this.isOffScreen) {
            this.moveOff();
        }
    };
    return LandingView;
}(View));
/// <reference path='./Utils.ts'/>
var Share = (function () {
    function Share() {
    }
    Share.onTwitter = function (shareUrl, tweetWithoutUrl) {
        //build tweet URL
        var url = 'https://twitter.com/intent/tweet?';
        if (shareUrl) {
            url += 'url=' + encodeURIComponent(shareUrl) + "&";
        }
        if (tweetWithoutUrl) {
            url += 'text=' + encodeURIComponent(tweetWithoutUrl);
        }
        Utils.openWindow(url, 550, 480);
    };
    Share.onFacebook = function (shareUrl, title, description, message) {
        if (title === void 0) { title = null; }
        if (description === void 0) { description = null; }
        if (message === void 0) { message = null; }
        if (navigator.userAgent.match('CriOS')) {
            var url = 'http://www.facebook.com/sharer.php?s=100&p[url]=' + encodeURIComponent(shareUrl);
            Utils.openWindow(url, 550, 480);
        }
        else {
            var obj = {
                method: 'share',
                href: shareUrl
            };
            FB.ui(obj);
        }
        // Share.recordShare();
    };
    return Share;
}());
/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./scaffolding/Share.ts'/>
var Social = (function (_super) {
    __extends(Social, _super);
    function Social(container, data, del) {
        var _this = this;
        _super.call(this, container, data, del);
        this.fb = false;
        this.imposium = new Imposium.ImposiumClient();
        $('.twitter').on('click ', function (e) { return _this.twShare(e); });
        $('.facebook').on('click ', function (e) { return _this.fbShare(e); });
        $('.donate').on('click touchstart', function (e) { return _this.donate(e); });
        EventBus.addEventListener(Social.GEN_BITLY, function () { return _this.getBitly(); }, this);
    }
    Social.prototype.donate = function (e) {
        window.open('http://www.cancer.ca/en/donate/?region=ns&s_src=Cancer.ca_Sitecling&s_subsrc=GordDownie', '_blank');
        ga('send', 'event', 'share', 'donate');
    };
    Social.prototype.getBitly = function () {
        var _this = this;
        this.bitly = null;
        this.imposium.getBitly(Main.currentExperience, Main.config.baseUrl, function (e) { return _this.gotBitly(e); }, function (e) { return _this.bitlyError(e); });
    };
    Social.prototype.gotBitly = function (e) {
        this.bitly = e.bind.url;
    };
    Social.prototype.twShare = function (e) {
        var url = (Main.currentExperience) ? (this.bitly) ? this.bitly : this.bitly : Main.config.baseUrl;
        var txt = (Main.currentExperience) ? Main.config.descVideo : Main.config.desc;
        if (window.location.pathname == "/stream") {
            txt = Main.config.twstreamShare;
        }
        ga('send', 'event', 'share', 'tw');
        Share.onTwitter(url, txt);
    };
    Social.prototype.fbShare = function (e) {
        var url = (Main.currentExperience) ? this.bitly : Main.config.baseUrl;
        var body = (Main.currentExperience) ? Main.config.fbdesc : Main.config.fbdesc;
        Share.onFacebook(url, Main.config.title, body, body);
        ga('send', 'event', 'share', 'fb');
    };
    Social.prototype.bitlyError = function (e) {
        console.log("Error With Bitly");
    };
    Social.GEN_BITLY = "genbitly";
    return Social;
}(Component));
/// <reference path='./scaffolding/Main.ts'/>
/// <reference path='./scaffolding/Utils.ts'/>
/// <reference path='./scaffolding/EventBus.ts'/>
/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./scaffolding/VideoPlayer.ts'/>
/// <reference path='./scaffolding/View.ts'/>
/// <reference path='./StreamView.ts'/>
/// <reference path='./LandingView.ts'/>
/// <reference path='./Social.ts'/>
var cont = $("#container");
var main = new Main(CONFIG);
var landingView = new LandingView(cont, {}, "");
var streamView = new StreamView(cont, {}, "stream");
var videoPlayer = new VideoPlayer(cont, {}, "v");
var social = new Social(cont, {}, this);
