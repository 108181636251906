class Component {

    /**
     * The config JSON data associated with this component.
     */
    public data:any;

    /**
     * The jquery object container for this view.
     */
    public container:JQuery;

    /**
     * All of this views content DOM elements.
     */
    public content:JQuery;

    /**
     * The Class that created this instance.
     */
    public delegate:any;

    /**
     * Whether of not this instance is visible or not.
     */
    public onStage:boolean = false;

    /**
     * Timeout used when showing this view.
     */
    public displayTimeout:number;

    /**
     * Timeout used when hiding this view.
     */
    public hideTimeout:number;

    /**
     * The delay for setting display:none on this view after the showContent class is removed.
     */
    public hideDelay:any = 0;

    /**
     * The delay for adding the showContent class to this view once display:block has been set.
     */
    public showDelay:number = 25;

    /**
     * The object containing event listeners added to this specific component
     */
    private listeners:any = {};

    /**
     * The object containing event listeners to be fired once added to this component
     */
    private onceListeners:any = {};

    public sWidth:number;
    public sHeight:number;

    /**
     * The class to use for showing this component
     */
    public displayClass:string = "block";

    public rivets:any;

    public uuid:string = Utils.generateUUID();

    /**
     * Stores the global vars
     *
     * @param container A jQuery object containing the parent div for this view.
     * @param data      The config JSON data associated with this component.
     * @param delegate  The Class that instantiated this view.
     */
    constructor(container:JQuery, data:any, delegate:any) {

        this.container = container;
        this.delegate = delegate;
        this.data = data;

        this.bindEvents();
    }

    public resize(e = null) {
        this.sHeight = window.innerHeight;
        this.sWidth = window.innerWidth;
    }

    /**
     * Sets this.content with a JQuery object passed in (usually cloned from Main.templates)
     *
     * @param v JQuery wrapped DOM element
     */
    public setContent(v:JQuery):void {

        this.content = v;

        this.rivets = rivets.bind(this.content, {data:this.data});

        this.container.append(this.content);
    }

    /**
     * Adds an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to add an event listener for.
     * @param callback The function we want to call if that event is dispatched.
     * @param caller   Who called this
     */
    public on(evt:string, callback:any, caller:any) {

        if (!this.listeners[evt]) {
            this.listeners[evt] = {};
        }

        this.listeners[evt][caller.guid] = callback;
    }

    /**
     * Removes an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to remove an event listener for.
     * @param callback The function we want to remove from the listeners array for that event.
     * @param caller   Who called this
     */
    public off(evt:string, callback:any, caller:any) {

        var listeners = this.listeners[evt];
        delete listeners[caller.guid];
    }

    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    public dispatch(evt:string, data:any = null) {

        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];

        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }

        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    }

    /**
     * Binds all of the event listeners for this component.
     */
    public bindEvents() {

        $(window).bind('resize orientationchange', (e)=>this.resize(e));
    }

    /**
     * Unbinds all of the Event listeners for this component.
     */
    public unbindEvents() {

        $(window).unbind('resize orientationchange', (e)=>this.resize(e)); //TODO: MAKE THIS WORK
    }

    /**
     * Adds display block to this.content, and calls showContent
     */
    public showMe():void {

        var style = (this.displayClass == "visible") ? "visibility" : "display";
        this.content.css(style, this.displayClass);
        this.onStage = true;

        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(()=> {
            this.showContent();
        }, this.showDelay);
    }

    /**
     * Adds display none to this.content, calls hideContent
     */
    public hideMe():void {
        this.hideContent();
        this.onStage = false;

        var style = (this.displayClass == "visible") ? "visibility" : "display";
        var hide = (style == "display") ? "none" : "hidden";

        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(()=> {
            this.content.css(style, hide);
        }, this.hideDelay);
    }

    /**
     * Adds the showContent class and removes the hideContent class from this component.
     */
    public showContent():void {
        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    }

    /**
     * Adds the hideContent class and removes the showContent class from this component.
     */
    public hideContent():void {
        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    }

    /**
     * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
     */
    public killMe():void {

        this.unbindEvents();
        if(this.content){
            this.content.remove();
        }
        this.content = null;
    }
}
