class StopWatch{
     
    private interval: number;
    private startTime: any;
    private displayTime: number = 0;
    private displayDiv: JQuery;

    public start(container){
        this.displayDiv = container;
        clearInterval(this.interval)
        this.startTime = Date.now();
        this.interval = setInterval(()=>this.update(), 33);
    }
    
    public stop(){
        clearInterval(this.interval);
        this.startTime = 0;
        this.displayTime = 0;
    }

    public render(){
        let time = this.formatTime();
        this.displayDiv.empty().append(time)

    }

    public reset(){
        if (this.displayDiv) {
            this.displayDiv.empty().append("00:00:00")
        }
    }

    public update() {
        this.displayTime += this.delta();
        this.render();
    }

    public formatTime(){
       let sec_num:any = Math.floor(this.displayTime/1000);
       let hours:any   = Math.floor(sec_num / 3600);
       let minutes:any = Math.floor((sec_num - (hours * 3600)) / 60);
       let seconds:any = sec_num - (hours * 3600) - (minutes * 60);
       let milliseconds: any = this.displayTime/1000;
       let msFix = Math.abs(milliseconds - Math.trunc(milliseconds));
       let msFormat = Math.trunc(msFix * 100);

       // if (hours   < 10) {hours   = "0"+hours;}
       if (minutes < 10) {minutes = "0"+minutes;}
       if (seconds < 10) {seconds = "0"+seconds;}
       if (msFormat < 10) {msFormat = "0"+msFormat;} 
       return minutes+':'+seconds+':'+msFormat;
    }

    public delta() {
        let currentTime = Date.now()
        let d = currentTime - this.startTime;
        
        this.startTime = currentTime;
        return d;
      }

    
}


