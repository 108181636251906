/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./scaffolding/Share.ts'/>
class Social extends Component{
    private bitly: any;
    private imposium: any;
    private fb: boolean = false;

    public static GEN_BITLY:string = "genbitly";

    constructor(container, data, del){
        super(container, data, del);
        this.imposium = new Imposium.ImposiumClient();
        $('.twitter').on('click ', (e) => this.twShare(e));
        $('.facebook').on('click ', (e) => this.fbShare(e));
        $('.donate').on('click touchstart', (e)=>this.donate(e));
        EventBus.addEventListener(Social.GEN_BITLY, ()=> this.getBitly(), this);
    }

    private donate(e){
        window.open('http://www.cancer.ca/en/donate/?region=ns&s_src=Cancer.ca_Sitecling&s_subsrc=GordDownie', '_blank');

        ga('send', 'event', 'share', 'donate');
    }

    private getBitly(){
        this.bitly = null;
        this.imposium.getBitly(Main.currentExperience, Main.config.baseUrl, (e)=>this.gotBitly(e), (e)=>this.bitlyError(e));     
    }

    private gotBitly(e){
        this.bitly = e.bind.url;
    }

    public twShare(e) {
        var url = (Main.currentExperience) ? (this.bitly) ? this.bitly : this.bitly : Main.config.baseUrl;
        var txt = (Main.currentExperience) ? Main.config.descVideo : Main.config.desc;
        if(window.location.pathname == "/stream"){
            txt = Main.config.twstreamShare
        }
        ga('send', 'event', 'share', 'tw');
        Share.onTwitter(url, txt);
    }

    public fbShare(e){

        var url = (Main.currentExperience) ? this.bitly : Main.config.baseUrl;
        var body = (Main.currentExperience) ? Main.config.fbdesc : Main.config.fbdesc;
        Share.onFacebook(url, Main.config.title, body, body);

       ga('send', 'event', 'share', 'fb');
    }

    private bitlyError(e ){
        console.log("Error With Bitly")
    }

}

