/// <reference path='./scaffolding/View.ts'/>
class StreamView extends View{
    
    private videoDiv: JQuery;
    private circle: JQuery;
    private player: any;
    private nxtBtn: JQuery;
    private playBtn: JQuery;
    private legacyPlayer: any;
    private isHls: boolean = false;
    private isiOS: boolean = false;
    private isOffScreen: boolean = false;
    private seeked: boolean = false;
    private isMobile: boolean = false;
    private playerSetUp: boolean = false;
    private moveTimeout: number;
    private startVideoTimeout: number;

    private stream: string;
    // private stream: string = "http://devimages.apple.com/iphone/samples/bipbop/gear1/prog_index.m3u8";

    private isPlaying: boolean = false;


    constructor(container, data, id){
        super(container, data, id);
        this.setContent(Main.templates.find('#streamView').clone());
        this.videoDiv = this.content.find('#vid');
        this.player = this.content.find('.player');
        this.circle = this.content.find('.circle');
        this.playBtn = this.content.find('.playBtn');
        this.nxtBtn = this.content.find('.nextBtn');
        this.nxtBtn.on('click', () =>this.goToLanding());
        this.stateChanged();
        $(window).bind('resize oriantationchange', $.proxy(this.resize, this));
        this.fitPlayerToScreen();
        this.getUrl();
        let mobileCheck = Utils.detectMobileOS();
        if(mobileCheck.os !="unknown"){
            this.isMobile = true;
            if(mobileCheck.os == "iOS"){
                this.isiOS = true;
            }
        } 
        if(this.isMobile){
            this.player.on('click', ()=>this.startVideo())
        }
    }

    public showMe() {
        super.showMe(this.id);
        this.resize();
       this.content.addClass('move')    

        Main.currentExperience = "stream";
        if (this.isOffScreen) {
            this.content.css('right', "0px");
            this.isOffScreen = false;
        }

        if(!this.isPlaying){
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(() => {
                this.circle.addClass('hide')
                this.showVideo();
            }, 2500)
        } else {
            this.startVideo();
        }
    }

    private showVideo() {
       
       if(this.isMobile){
           this.addVideo();
           this.isHls = false;
       } else {
           this.setupLegacyPlayer();
           this.isHls = true;
       }
    }

    public goToLanding() {
    
        Utils.pushState('/');

        ga('send', 'event', 'view', 'landing');
	}

    private getUrl(){
        $.getJSON( "https://api.raiseoneforgord.com/stream", ( data ) => {
           this.stream = data.url;
        })
    }


    private startVideo(){
        if(!this.isMobile){
            if(!this.isOffScreen){
                jwplayer('legacyPlayer').play();
                this.isPlaying = true;
            }
            this.nxtBtn.addClass('show')
        } else {
            let videoTag: any = this.videoDiv[0];
            this.videoDiv.addClass('show');
            this.playBtn.removeClass('show');
            let currentTime = videoTag.currentTime;
            if(!this.seeked){
                videoTag.onplay=()=>{
                    let max = videoTag.duration * 0.85;
                    let seekTime = Math.random() * (max - 0) + 0;
                    videoTag.currentTime = seekTime;
                }
                videoTag.load();
                this.seeked = true;

                if(this.isiOS){
                    this.nxtBtn.addClass('show')
                }
            } else {
                videoTag.play();
            }
        }
    }

    private addVideo(){
        let videoTag:any = this.videoDiv[0];
        let src = $('<source src="'+this.stream+'" type="application/x-mpegURL">')
        this.videoDiv.append(src)
        this.isPlaying = true;
        this.playBtn.addClass('show');
        videoTag.addEventListener('ended', function() {
           videoTag.play();
        });

        videoTag.addEventListener('error', ()=>{
            this.getUrl();
            let src = $('<source src="'+this.stream+'" type="application/x-mpegURL">')
            this.videoDiv.append(src)
        });
    }

    private resize(){
        this.fitPlayerToScreen();
        if (this.isOffScreen) {
            this.content.removeClass('move')
            this.isOffScreen = false;
            this.moveOff();
        }
    }

    public hideMe(){
        if(!this.isMobile && jwplayer('legacyPlayer').getState() == "playing"){
            jwplayer('legacyPlayer').pause();
        } else {
            let videoTag: any = this.videoDiv[0];
            videoTag.pause();
        }
        super.hideMe();
        if(!this.isOffScreen){
            this.moveOff();        
        }
    }

    private moveOff(){
        if(!this.isOffScreen){
            let offset = window.innerWidth;
            this.content.css('right', -offset + "px");
            this.isOffScreen = true;
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(() => {
                this.content.addClass('move')
            }, 50)
        }
    }

    private fitPlayerToScreen(){
       let sWidth = window.innerWidth;
       let sHeight = window.innerHeight;
        if(this.isMobile){
            if(this.isiOS){ 
                let pos = Utils.fitToContainer({
                    'containerWidth':sWidth,
                    'containerHeight':sHeight,
                    'contentWidth':1920,
                    'contentHeight':1080,
                    'scaleMode':'proportionalInside',
                    'hAlign':'center',
                    'vAlign':'center'
                });
            }
            let pos = {
                'width':sWidth,
                'height':sHeight,
                'top':0,
                'left':0
            }
            this.player.css(pos)
        } else {
            this.player.css({'width':sWidth, 'height':sHeight})
        }
    }

    private setupLegacyPlayer() {
        this.isPlaying = true; 
        this.legacyPlayer = jwplayer('legacyPlayer').setup({
            file: this.stream,
            autostart: true,
            controls: true,
            repeat: true,
            height: "100%",
            width: "100%"
        });
        let scope = this;

        jwplayer('legacyPlayer').onTime(function(){
            if(!scope.seeked){
              let duration = this.getDuration()
              let max = duration * 0.85;
              let seekTime = Math.random() * (max - 0) + 0;
              this.seek(seekTime);
              scope.seeked = true;
              scope.nxtBtn.addClass('show');
            }
        })

        jwplayer('legacyPlayer').onError(()=>{
            this.getUrl();
            jwplayer('legacyPlayer').setup({
                  file: this.stream,
               });
        })   
    }
}


