/// <reference path='./scaffolding/VideoPlayer.ts'/>
/// <reference path='./scaffolding/View.ts'/>
/// <reference path='./RecordComponent.ts'/>


class LandingView extends View {
      
    private btnUpload: JQuery;
    private btnRecord: JQuery;
    private videoUpload: JQuery;
    private checkBox: JQuery;
    private streamBtn: JQuery;
    private btnSubmit: JQuery;
    private experienceId: any;
    private userVideo: any;
    private isUploadedVideo: boolean = false;
    private isRecordVideo: boolean = false;
    private recordView: RecordComponent;
    private isOffScreen: boolean = false;
    private moveTimeout: number;

    constructor(container, data, id){
        super(container, data, id);

        this.setContent(Main.templates.find('#landingView').clone());
        this.videoUpload = this.content.find('.videoUpload');
        this.btnRecord = this.content.find('.btnRecord')
        this.checkBox = this.content.find('.streamCheck')
        this.btnSubmit = this.content.find('.btnSubmit')

        this.streamBtn = this.content.find('.stream').on('click', () =>this.goToStream());
        this.btnUpload = this.content.find('.btnUpload').one('click', $.proxy(this.uploadPhoto, this));
        this.btnSubmit.on("click", ()=> this.showVideoPlayer());
        this.videoUpload.on("change", ()=> this.showVideoName());
        this.btnRecord.on('click', $.proxy(this.showVideoRecorder, this));
        
        $(window).bind('resize', $.proxy(this.resize, this));
        this.stateChanged();
        let mobileCheck = Utils.detectMobileOS();
        if(mobileCheck.os =="iOS"){
            let width = screen.availWidth
            let height = screen.availHeight
            this.container.addClass('safari');
           if(width == 320 && height == 548){
               this.container.addClass('iphone5');
           }
        }
        if (navigator.userAgent.indexOf('safari') == -1 && mobileCheck.os == "unknown"){
            if(Main.config.browser != "safari"){
              this.recordView = new RecordComponent(this.content, {}, this);
              this.btnRecord.addClass('show')
            } else {
                
                this.btnUpload.addClass('safari')
                this.content.find('input').addClass('safari')
            }
        }


    }

    public showMe(){
        super.showMe(this.id);
        if(this.isOffScreen){
            this.content.css('left', "0px");
            this.isOffScreen = false;
            setTimeout(()=>{
                this.content[0].style.overflowY='auto'
            }, 500)
        }

    }

    public goToStream(){

        this.moveOff()
        Utils.pushState('/stream');
        this.content[0].style.overflowY='hidden'
        ga('send', 'event', 'view', 'stream');
        this.hideMe();
    }

    private moveOff(){
        if(!this.isOffScreen){
            let offset = window.innerWidth;
            this.content.css('left', -offset + "px");
            this.isOffScreen = true;
            clearTimeout(this.moveTimeout);
            this.moveTimeout = setTimeout(() => {
                this.content.addClass('move')
            }, 50)
        }
    }

    private uploadPhoto(){

        this.videoUpload.click();
        this.isUploadedVideo = true;
        this.isRecordVideo = false;
        this.btnUpload.one('click', $.proxy(this.uploadPhoto, this));

        ga('send', 'event', 'upload', 'Video');
    }

    private showVideoPlayer(){ 
        // let videoFile:any = this.videoUpload[0];     
        // if(videoFile.files.length != 0){
            Main.userVideoInStream =  this.checkBox.is(':checked')
            this.btnRecord.removeClass('up');
            this.btnUpload.removeClass('up');
            this.content.find('.videoFileName').empty();
            this.content.find('.recordFileName').empty();

            $("#socialContainer").addClass('hide'); 
            this.handleExperience();
            ga('send', 'event', 'page_view', 'VideoPlayer');
        // }
    }

    private showVideoName(){
        let videoFile:any = this.videoUpload[0];
        if(videoFile.files.length != 0){
            this.isUploadedVideo = true;
            this.isRecordVideo = false;

            this.btnUpload.addClass('up');
            this.btnRecord.removeClass('up');
            this.content.find('.videoFileName').html(videoFile.files[0].name);
            this.content.find('.recordFileName').html('');
        }
    }

    private handleExperience(){
           if(this.isUploadedVideo) {
                let userVideo: any = this.videoUpload;
                let videoVal = userVideo[0]
                this.userVideo = videoVal
           } else if(this.isRecordVideo) {
               let scope = this;
               let blob = new Blob(this.recordView.chunks, {type : 'video/webm'});
               let fileOfBlob = new File([blob], 'userVideo.webm');
               this.userVideo = fileOfBlob
            }
        this.createExperience()
    }

    private showVideoRecorder(){
        this.recordView.showMe();
        let videoFile:any = this.videoUpload[0];     

        ga('send', 'event', 'page_view', 'VideoRecorder');
    }

    public userRecording(){
        this.isUploadedVideo = false;
        this.isRecordVideo = true;
        // this.showVideoPlayer();

        this.btnRecord.addClass('up');
        this.btnUpload.removeClass('up');
        let videoFile:any = this.videoUpload[0];
        if(videoFile.files.length != 0){ 
            this.content.find('.videoFileName').empty();
        }
        this.content.find('.recordFileName').html('userRecording.webm');

        ga('send', 'event', 'created', 'Video');
    }


    private createExperience(){

        EventBus.dispatchEvent(VideoPlayer.SHOW_PLAYER);
        let userName =  this.content.find('.name').val();
        let city =  this.content.find('.city').val();
        let year =  this.content.find('.year').val();
        let where =  this.content.find('.where').val();
        let people =  this.content.find('.people').val();
        let memory =  this.content.find('.memory').val();

        let data = {
            'task': 'solo-experience',
            'id': Utils.generateUUID(),
            'story_id': Main.config.storyId,
            'inventory': {
                "user_video": this.userVideo,
                'addToStream': Main.userVideoInStream,
                'name':userName,
                'venue':where,
                'when':year,
                'with':people,
                'message':memory,
                'where':city
            }
        }
        this.imposium.createExperience(data, $.proxy(this.experienceCreated, this), $.proxy(this.experienceError, this));
  
    }

    //Once the experience is created, make sure we have an experience id, and start the exent processor
    private experienceCreated(expData){ 
       let userVideo: any = this.videoUpload;
       let value = userVideo[0]

       this.isUploadedVideo = false;
       this.isRecordVideo = false;
       value.value = "";
        if(expData.bind.success == 1){
            this.experienceId = expData.bind.experience_id;
            EventBus.dispatchEvent(VideoPlayer.GOT_ID, { experienceId: this.experienceId })

            ga('send', 'event', 'experience', 'created');

        }else{
            this.experienceError(null);
        }
    }

    public resize(){
        if (this.isOffScreen) {
            this.content.removeClass('move')
            this.isOffScreen = false;
            this.moveOff();
        }
    }

    private experienceError(e) {
        console.error("Experience Error");
        console.log(e);

        ga('send', 'event', 'experience', 'error');
    }

    public hideMe(){
        super.hideMe();
        if(!this.isOffScreen){
            this.moveOff();
        }
    }
}

