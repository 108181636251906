/// <reference path='./scaffolding/Component.ts'/>
/// <reference path='./StopWatch.ts'/>
class RecordComponent extends Component{

    private mediaConstraints: any;
    private videoDiv: JQuery;
    private recordBtn: JQuery;
    private subControls: JQuery;
    private recordScreen: JQuery;
    private timerDiv: JQuery;
    private checkBox: JQuery;

    private streamRecorder: any;
    private stream: any;
    public chunks: any = [];
    public videoData:any;
    
    private timerTime: number = 0;
    private isRecording: boolean = false;
    private del: any;
    private stopWatch: StopWatch;

    constructor(container, data, del){
        super(container, data, del);
        this.del = del;
        this.setContent(Main.templates.find('.recordView').clone());
        this.mediaConstraints = {
            audio: true,
            video: true
        };
        this.videoDiv = this.content.find('video');
        this.recordBtn = this.content.find('.recordBtn');
        this.subControls = this.content.find('.subControls');
        this.recordScreen = this.content.find('.recordScreen');
        this.timerDiv = this.content.find('.timer');
        this.checkBox = this.content.find('.streamCheck')

        this.recordBtn.on('click', ()=>this.toggleRecord());
        this.content.find('.retryBtn').on('click', ()=>this.retry());
        this.content.find('.keepBtn').on('click', ()=>this.keep());
        this.content.find('.closeBtn').on('click', () => this.hideMe());

        this.stopWatch = new StopWatch();
    }

    private fitScreen(){
        let pos = Utils.fitToContainer({
            'containerWidth':this.recordScreen.width(),
            'containerHeight':this.recordScreen.height(),
            'contentWidth':this.videoDiv.width(),
            'contentHeight':this.videoDiv.height(),
            'scaleMode':'proportionalOutside',
            'hAlign':'center',
            'vAlign':'center'
        });
        this.videoDiv.css(pos);
    }

    public showMe(){
        super.showMe();
        navigator.getUserMedia(this.mediaConstraints, (stream)=>this.onMediaSuccess(stream), (e)=>this.onMediaError(e));
    }

    private onMediaSuccess(stream){
        this.stream = stream;
        let options = {
           mimeType : 'video/webm'
         }
        this.streamRecorder = new MediaRecorder(stream, options);
        let vidTag:any =  this.videoDiv[0]
        vidTag.src = URL.createObjectURL(stream)
        vidTag.play();    

        setTimeout(() => {
            this.timerDiv.addClass('show');
            this.recordBtn.addClass('show');
        }, 2200);

    }

    private toggleRecord(){
        if(!this.isRecording){
            this.startRecorder();
            this.isRecording = true;
            this.recordBtn.addClass('square');
        } else {
            this.stopRecording();
            this.stopWatch.stop();
            this.isRecording = false;
            this.recordBtn.removeClass('square');
        }
    }

    private startRecorder() {
        this.chunks = [];
        this.stopWatch.start(this.timerDiv)
        this.streamRecorder.start();
        let scope = this;
        this.streamRecorder.ondataavailable=function(e){
            scope.chunks.push(e.data);
       };

    }

    private retry(){
        this.stopWatch.reset();
        this.recordBtn.removeClass('hide');
        this.subControls.removeClass('show');
    }

    private keep(){
        Main.userVideoInStream =  this.checkBox.is(':checked')
        this.hideMe();  
        this.del.userRecording();
    }


    private stopTimer(){
        this.timerTime = 0;
    }

    public hideMe(){
        super.hideMe();
        if(this.isRecording){
            this.toggleRecord();
        }
        this.stopWatch.reset();
        let vidTag:any =  this.videoDiv[0]
        vidTag.src ="";
        this.stream.getTracks()[0].stop();
        this.stream.stop();
        this.retry();
    }

    private stopRecording(){
        this.isRecording = false;    
        this.streamRecorder.stop();
        this.recordBtn.addClass('hide');
        this.subControls.addClass('show');
    }


    private onMediaError(e) {
        console.error(e)
    }
}

